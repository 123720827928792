import { defineStore } from 'pinia';

const useCommonStore = defineStore('common', {
  state: () => ({
    identityArr: [
      { id: 1, name: '在校生' },
      { id: 2, name: '助理医生' },
      { id: 3, name: '执业医生' },
      { id: 4, name: '护理人员' },
      { id: 5, name: '其他（口腔行业从业者）' }
    ],
    channelArr: [
      { id: 1, name: '微信小程序' },
      { id: 2, name: 'PC' },
    ],
  }),

  getters: {},
  actions: {},
});

export default useCommonStore;
